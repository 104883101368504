body {
    background: #fafafa;
}

.home {
    font-family: 'Open Sans', sans-serif;
}

.search {
    font-family: proxima-nova, sans-serif;
}

.background {
    position: absolute;
    background-image: url("https://assets-global.website-files.com/5cec55545d0f47cfe2a39a8e/5e9aacff05bf3a25410f86a7_mountaion-backdrop.png"); 
    /*
        http://cdn.dribbble.com/users/548267/screenshots/13754208/media/ed2e36d62b8915490557337c4cc4f356.jpg
        https://assets-global.website-files.com/5cec55545d0f47cfe2a39a8e/5f1e18ba2968087c7389fdff_webbg%20(1).png
        https://assets-global.website-files.com/5cec55545d0f47cfe2a39a8e/5e9aacff05bf3a25410f86a7_mountaion-backdrop.png
        https://assets-global.website-files.com/5cec55545d0f47cfe2a39a8e/5da9fb71439112c81143c07e_small_waterfall-min.jpg
    */
    background-size: cover;
    background-position: center top;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.background-2 {
    background-image: url("https://assets-global.website-files.com/5cec55545d0f47cfe2a39a8e/5f1e18ba2968087c7389fdff_webbg%20(1).png"); 
    background-size: cover;
    background-position: center top;
}

.background-2>svg {
    width: 100%;
    height: 10vw;
}

.result {
    border: 1px solid transparent;
}

.result h2 {
    font-size: 1.15em;
}

.result h2 span {
    font-size: 11px;
    border: 1px solid #00278e;
}

.result p {
    margin-top: 1px;
}

.dygraph-roller {
    top: -2px !important;
    border-radius: 2px;
    height: 26px;
    width: 40px;
    padding: 0.4em 0.6em;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    border: none;
    z-index: 500 !important;
    font-size: 12px;
    text-align: center;
    margin: 0;
}

.dygraph-axis-label {
    color: #fff
}

.dygraph-axis-label-y {
    margin-right: 3px
}

.dygraph-axis-label-x {
    margin-top: 2em
}

.dygraph-legend {
    color: #ffffff;
    background: none;
    left: 65px !important;
    top: 35px !important
}

.dygraph-title {
    margin-left: 50px;
    text-align: center;
    color: #F6F9FC;
    line-height: 1em;
    font-weight: 700;
    font-size: 0.9em;
}
